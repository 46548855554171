<template>
  <div>
    <v-row>
      <v-col>
        <v-card-subtitle>Productos mas vendidos</v-card-subtitle>
      </v-col>
      <v-col class="text-right">
        <v-btn-toggle class="ml-2" v-model="tipo_visual" dense mandatory multiple>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiChartBarStacked }}
                </v-icon>
              </v-btn>
            </template>
            <span>Grafica</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiTableEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Tabla</span>
          </v-tooltip>
        </v-btn-toggle>
        <dos-fechas
          v-if="filtrosShow == true"
          :fechas="[
            FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
            FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
          ]"
          @getFechas="getFechas"
        ></dos-fechas>
      </v-col>
    </v-row>

    <div v-show="tipo_visual.includes(0)">
      <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      <apexchart ref="grafricaRef" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div v-show="tipo_visual.includes(1)">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small icon color="success" class="pt-0 mt-0 mr-2" @click="exportExcel()">
                <v-icon>{{ icons.mdiFileExcel }}</v-icon>
              </v-btn>
            </template>
            <span>Exportar Excel</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense fixed-header height="350">
            <thead v-if="datos.length > 0">
              <tr>
                <th>Producto</th>
                <th>Und Vendida</th>
                <th>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="cargando == true">
                <td colspan="7" class="text-center">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  <span class="pt-2"> Cargando datos</span>
                </td>
              </tr>
              <tr v-for="(fila, index) in datos" :key="index">
                <td>{{ fila.nombre }}</td>
                <td>{{ fila.cant }}</td>
                <td>${{ fila.total }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import { mdiCalendarMonth, mdiCalendarToday, mdiTableEye, mdiChartBarStacked, mdiFileExcel } from '@mdi/js'
import moment from 'moment'
export default {
  props: {
    filtrosShow: Boolean,
    fechaIni: Object,
  },
  components: {
    DosFechas,
  },
  setup(props) {
    const grafricaRef = ref(null)
    const tipo_visual = ref([0])
    const datos = ref([])
    const series = ref([
      {
        name: 'Venta Neta',
        data: [],
      },
    ])
    const chartOptions = ref({
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => {
          if (val > 1000000) {
            return (val / 1000000).toFixed(2) + 'M'
          } else if (val > 1000) {
            return (val / 1000).toFixed(2) + 'K'
          } else {
            return val.toFixed()
          }
        },
      },
      xaxis: {
        categories: [],
      },
      noData: {
        text: 'No se encontro resultados',
        align: 'center',
        verticalAlign: 'middle',
      },
    })
    const cargando = ref(true)
    const filtros = ref({
      fecha: {
        desde: moment(),
        hasta: moment(),
      },
      id_puesto: -1,
      limit: 15,
    })

    onBeforeMount(() => {
      if (props.fechaIni !== undefined) {
        filtros.value.fecha.desde = props.fechaIni.desde
        filtros.value.fecha.hasta = props.fechaIni.hasta
      }
      cargarDatos()
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      filtros.value.grupo_negocio = fecha.grupo_negocio
      cargarDatos()
    }

    const cargarDatos = () => {
      cargando.value = true

      datos.value = []
      let seriesTemp = []
      let xaxisTemp = []
      MaestroService.RptDashProductoMasVendidos(filtros.value)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data)
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
              response.data.datos.forEach((element, i) => {
                seriesTemp.push(element.cant)
                xaxisTemp.push(element.nombre)
              })

              grafricaRef.value.updateOptions({
                xaxis: {
                  categories: xaxisTemp,
                },
              })
              grafricaRef.value.updateSeries([
                {
                  name: 'Uds Vendidas',
                  data: seriesTemp,
                },
              ])

              console.log({
                xaxis: {
                  categories: xaxisTemp,
                },
              })
              console.log(seriesTemp)
            } else {
              grafricaRef.value.updateOptions({
                xaxis: {
                  categories: xaxisTemp,
                },
              })
              grafricaRef.value.updateSeries([
                {
                  name: 'Uds Vendidas',
                  data: seriesTemp,
                },
              ])
            }
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const exportExcel = () => {
      let dato = {
        nombreDocumento: 'Productos mas vendidos ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        nombreHoja: 'hoja1',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 30 }],
      }

      dato.cabecera = ['Producto', 'Unidades', 'Total $']
      datos.value.forEach((item, i) => {
        let row = [
          { v: item.nombre, t: 's' },
          { v: item.cant, t: 'n' },
          {
            v: item.total,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
        ]
        dato.rows.push(row)
      })

      FuncionesGenerales.exportarDataExcel(dato)
    }

    return {
      series,
      chartOptions,
      cargarDatos,
      grafricaRef,
      filtros,
      getFechas,
      FuncionesGenerales,
      tipo_visual,
      datos,
      cargando,
      icons: {
        mdiCalendarMonth,
        mdiCalendarToday,
        mdiTableEye,
        mdiChartBarStacked,
        mdiFileExcel,
      },
      exportExcel,
    }
  },
}
</script>