<template>
  <div>
    <v-row>
      <v-col>
        <v-card-subtitle> Balance general </v-card-subtitle>
      </v-col>
      <v-col class="text-right">
        <v-btn-toggle v-model="tipo_intervalo" dense mandatory color="primary">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiCalendarMonth }}
                </v-icon>
              </v-btn>
            </template>
            <span>Mensual</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiCalendarToday }}
                </v-icon>
              </v-btn>
            </template>
            <span>Diario</span>
          </v-tooltip>
        </v-btn-toggle>

        <v-btn-toggle class="ml-2" v-model="tipo_visual" dense mandatory multiple>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiChartBarStacked }}
                </v-icon>
              </v-btn>
            </template>
            <span>Grafica</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiTableEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Tabla</span>
          </v-tooltip>
        </v-btn-toggle>
        <dos-fechas
          v-if="filtrosShow == true"
          :fechas="[
            FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
            FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
          ]"
          @getFechas="getFechas"
        ></dos-fechas>
      </v-col>
    </v-row>

    <div v-show="tipo_visual.includes(0)">
      <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      <apexchart ref="grafricaRef" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-radio-group v-model="tipoBusqueda" row @change="cargarGrafica()">
              <v-radio label="Venta Bruta" value="2"></v-radio>
              <v-radio label="Venta Neta" value="1"></v-radio>
              <v-radio label="Iva" value="3"></v-radio>
              <v-radio label="Servicio" value="4"></v-radio>
              <v-radio label="Administracion" value="5"></v-radio>
              <v-radio label="Locatarios" value="6"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4" class="text-center">
            <strong>Total</strong> <br />
            <span>{{ total_general }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-show="tipo_visual.includes(1)">
      <v-row>
        <v-col cols="12" class="text-right">
          <export-excel :data="exportarDataExcel()" :name="'Balance general grafica.xls'" style="width: unset">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small icon color="success" class="pt-0 mt-0 mr-2">
                  <v-icon>{{ icons.mdiFileExcel }}</v-icon>
                </v-btn>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
          </export-excel>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense fixed-header height="350">
            <thead>
              <tr>
                <th>Fecha</th>
                <th v-for="item in series" :key="item.name">
                  {{ item.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="cargando == true">
                <td colspan="7" class="text-center">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  <span class="pt-2"> Cargando datos</span>
                </td>
              </tr>
              <tr v-for="(fila, index) in datos" :key="index">
                <td>{{ fila.fecha }}</td>
                <td>{{ fila.total_neto }}</td>
                <td>{{ fila.total_bruto }}</td>
                <td>{{ fila.total_iva }}</td>
                <td>{{ fila.total_servicio }}</td>
                <td>{{ fila.total_administracion }}</td>
                <td>{{ fila.total_puesto }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import moment from 'moment'
import { mdiCalendarMonth, mdiCalendarToday, mdiTableEye, mdiChartBarStacked, mdiFileExcel } from '@mdi/js'
export default {
  props: {
    filtrosShow: Boolean,
    fechaIni: Object,
  },
  components: {
    DosFechas,
  },
  setup(props) {
    const grafricaRef = ref(null)
    const tipo_intervalo = ref(0)
    const tipo_visual = ref([0])
    const datos = ref({})
    const total_general = ref(0)
    const tipoBusqueda = ref('2')

    const series = ref([
      {
        name: 'Venta Neta',
        data: [],
      },
    ])
    const attrs = ref({
      class: 'mx-auto',
    })

    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'line',
        stacked: 'true',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => {
          if (val > 1000000) {
            return (val / 1000000).toFixed(2) + 'M'
          } else if (val > 1000) {
            return (val / 1000).toFixed(2) + 'K'
          } else {
            return val.toFixed()
          }
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },

      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],

      xaxis: {
        categories: [],
      },
      legend: {
        offsetY: 40,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' $'
              },
            },
          },
        ],
      },

      grid: {
        borderColor: '#f1f1f1',
      },
      noData: {
        text: 'No se encontro resultados',
        align: 'center',
        verticalAlign: 'middle',
      },
      yaxis: {
        labels: {
          formatter: val => {
            return (val / 1000).toFixed(2) + 'K'
          },
        },
      },
    })
    const cargando = ref(false)
    const filtros = ref({
      fecha: {
        desde: moment(),
        hasta: moment(),
      },
      id: -1,
    })

    onBeforeMount(() => {
      if (props.fechaIni !== undefined) {
        filtros.value.fecha.desde = props.fechaIni.desde
        filtros.value.fecha.hasta = props.fechaIni.hasta
      }
      cargarDatos()
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      filtros.value.grupo_negocio = fecha.grupo_negocio
      cargarDatos()
    }
    watch(tipo_intervalo, () => {
      cargarDatos()
    })

    const cargarDatos = () => {
      if (tipo_intervalo.value == 0) {
        cargarDatosMes()
      } else {
        cargarDatosDia()
      }
    }

    const cargarGrafica = () => {
      series.value = []
      if (tipoBusqueda.value == 1) {
        series.value.push({
          name: 'Venta neta',
          type: 'column',
          json: 'total_bruto',
          data: seriesTempVentaBruta,
        })

        total_general.value = 0
        seriesTempVentaBruta.forEach(ite => {
          total_general.value += ite
        })

        grafricaRef.value.updateOptions({
          title: {
            text: `Ventas neta ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (tipoBusqueda.value == 2) {
        series.value.push({
          name: 'Venta bruta',
          type: 'column',
          json: 'total_neto',
          data: seriesVentaNeta,
        })
        total_general.value = 0
        seriesVentaNeta.forEach(ite => {
          total_general.value += ite
        })
        grafricaRef.value.updateOptions({
          title: {
            text: `Ventas brutas ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (tipoBusqueda.value == 3) {
        series.value.push({
          name: 'Total IVA',
          type: 'column',
          json: 'total_iva',
          data: seriesTempIva,
        })
        total_general.value = 0
        seriesTempIva.forEach(ite => {
          total_general.value += ite
        })
        grafricaRef.value.updateOptions({
          title: {
            text: `IVA ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (tipoBusqueda.value == 4) {
        series.value.push({
          name: 'Total Servicio',
          type: 'column',
          json: 'total_servicio',
          data: seriesTempServicio,
        })
        total_general.value = 0
        seriesTempServicio.forEach(ite => {
          total_general.value += ite
        })
        grafricaRef.value.updateOptions({
          title: {
            text: `Servicio ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (tipoBusqueda.value == 5) {
        series.value.push({
          name: 'Total Administracion',
          type: 'column',
          json: 'total_administracion',
          data: seriesTempPorAdmin,
        })
        total_general.value = 0
        seriesTempPorAdmin.forEach(ite => {
          total_general.value += ite
        })
        grafricaRef.value.updateOptions({
          title: {
            text: `Total Administracion ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (tipoBusqueda.value == 6) {
        series.value.push({
          name: 'Total Locatario',
          type: 'column',
          json: 'total_puesto',
          data: seriesTempPorLocatario,
        })
        total_general.value = 0
        seriesTempPorLocatario.forEach(ite => {
          total_general.value += ite
        })
        grafricaRef.value.updateOptions({
          title: {
            text: `Total Locatarios ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,
            floating: false,
            align: 'center',
            style: {
              color: '#444',
            },
          },
        })
      }
      if (total_general.value > 1000000) {
        total_general.value = (total_general.value / 1000000).toFixed(2) + 'M'
      } else if (total_general.value > 1000) {
        total_general.value = (total_general.value / 1000).toFixed(2) + 'K'
      } else {
        total_general.value = total_general.value.toFixed()
      }
      grafricaRef.value.updateSeries(series.value)
    }
    let seriesVentaNeta = []
    let seriesTempVentaBruta = []
    let seriesTempIva = []
    let seriesTempServicio = []
    let seriesTempPorAdmin = []
    let seriesTempPorLocatario = []
    let xaxisTemp = []
    const cargarDatosMes = () => {
      cargando.value = true
      datos.value = []

      MaestroService.RptDashCierreDiaMensual(filtros.value)
        .then(response => {
          seriesVentaNeta = []
          seriesTempVentaBruta = []
          seriesTempIva = []
          seriesTempServicio = []
          seriesTempPorAdmin = []
          seriesTempPorLocatario = []
          xaxisTemp = []
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data)
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
              response.data.datos.forEach((element, i) => {
                xaxisTemp.push(element.fecha)
                seriesVentaNeta.push(element.total_neto)
                seriesTempVentaBruta.push(element.total_bruto)
                seriesTempIva.push(element.total_iva)
                seriesTempServicio.push(element.total_servicio)
                seriesTempPorAdmin.push(element.total_administracion)
                seriesTempPorLocatario.push(element.total_puesto)
              })
            }
            chartOptions.value.xaxis = {
              categories: xaxisTemp,
            }
            grafricaRef.value.updateOptions({
              xaxis: {
                categories: xaxisTemp,
              },
            })
            cargarGrafica()
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatosMes',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const cargarDatosDia = () => {
      cargando.value = true
      datos.value = []
      MaestroService.RptDashCierreDia(filtros.value)
        .then(response => {
          seriesVentaNeta = []
          seriesTempVentaBruta = []
          seriesTempIva = []
          seriesTempServicio = []
          seriesTempPorAdmin = []
          seriesTempPorLocatario = []
          xaxisTemp = []
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data)
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
              response.data.datos.forEach((element, i) => {
                xaxisTemp.push(element.fecha)
                seriesVentaNeta.push(element.total_neto)
                seriesTempVentaBruta.push(element.total_bruto)
                seriesTempIva.push(element.total_iva)
                seriesTempServicio.push(element.total_servicio)
                seriesTempPorAdmin.push(element.total_administracion)
                seriesTempPorLocatario.push(element.total_puesto)
              })
            }
            chartOptions.value.xaxis = {
              categories: xaxisTemp,
            }
            grafricaRef.value.updateOptions({
              xaxis: {
                categories: xaxisTemp,
              },
            })

            cargarGrafica()
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatosDia',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const exportarDataExcel = () => {
      let data = []
      datos.value.forEach(element => {
        let objet = {}
        Object.keys(element).forEach((columna, i) => {
          if (i == 0) {
            if (typeof element[columna] === 'number') {
              objet = { ...objet, ['fecha']: element['fecha'].toString().replace('.', ',') }
            } else {
              objet = { ...objet, ['fecha']: element['fecha'] }
            }
          } else {
            const col = series.value.find(it => it.json == columna)
            if (col != undefined) {
              if (typeof element[columna] === 'number') {
                objet = { ...objet, [col.name]: element[columna].toString().replace('.', ',') }
              } else {
                objet = { ...objet, [col.name]: element[columna] }
              }
            }
          }
        })
        data.push(objet)
      })
      console.log(data)
      return data
    }
    return {
      datos,
      series,
      chartOptions,
      cargarDatos,
      grafricaRef,
      filtros,
      getFechas,
      FuncionesGenerales,
      tipo_intervalo,
      tipo_visual,

      icons: {
        mdiCalendarMonth,
        mdiCalendarToday,
        mdiTableEye,
        mdiChartBarStacked,
        mdiFileExcel,
      },
      attrs,
      cargando,
      exportarDataExcel,
      tipoBusqueda,
      cargarGrafica,
      total_general,
    }
  },
}
</script>