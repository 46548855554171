<template>
  <div>
    <v-row>
      <v-col>
        <v-card-subtitle>Ventas por dias de la semana</v-card-subtitle>
      </v-col>
      <v-col>
        <v-row class="text-right">
          <v-col>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mt-2" x-small color="primary" dark v-bind="attrs" v-on="on">
                  {{ tipos.find(i => i.id == tipoBusqueda).nombre }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="tipoBusqueda == item.id ? 'primary white--text' : ''"
                  link
                  v-for="item in tipos"
                  :key="item.id"
                  @click="tipoBusqueda = item.id"
                >
                  {{ item.nombre }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col>
            <v-btn-toggle v-model="tipo_visual" dense mandatory multiple>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on">
                    <v-icon color="green darken-2">
                      {{ icons.mdiChartBarStacked }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Grafica</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on">
                    <v-icon color="green darken-2">
                      {{ icons.mdiTableEye }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Tabla</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <v-col v-if="filtrosShow == true">
            <dos-fechas
              :fechas="[
                FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
              ]"
              @getFechas="getFechas"
            ></dos-fechas>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-show="tipo_visual.includes(0)">
      <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      <apexchart ref="grafricaRef" type="radar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div v-show="tipo_visual.includes(1)">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small icon color="success" class="pt-0 mt-0 mr-2" @click="exportExcel()">
                <v-icon>{{ icons.mdiFileExcel }}</v-icon>
              </v-btn>
            </template>
            <span>Exportar Excel</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense fixed-header height="350">
            <thead v-if="datos.length > 0">
              <tr>
                <th>Dia</th>
                <th>Venta neta</th>
                <th>N° comanda</th>
                <th>AVG Venta neta</th>
                <th>AVG N° comanda</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="cargando == true">
                <td colspan="7" class="text-center">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  <span class="pt-2"> Cargando datos</span>
                </td>
              </tr>
              <tr v-for="(fila, index) in datos" :key="index">
                <td>{{ fila.dia }}</td>
                <td>${{ fila.total_neto.toFixed(2) }}</td>
                <td>{{ fila.comandas }}</td>
                <td>${{ fila.promedio_ventas.toFixed(2) }}</td>
                <td>{{ fila.promedio_comandas.toFixed() }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import { mdiCalendarMonth, mdiCalendarToday, mdiTableEye, mdiChartBarStacked, mdiFileExcel } from '@mdi/js'
import moment from 'moment'
export default {
  props: {
    filtrosShow: Boolean,
    fechaIni: Object,
  },
  components: {
    DosFechas,
  },
  setup(props) {
    const grafricaRef = ref(null)
    const tipo_visual = ref([0])
    const tipos = ref([
      {
        id: 0,
        nombre: 'Venta neta',
      },
      {
        id: 1,
        nombre: 'N° Comandas',
      },
      {
        id: 2,
        nombre: 'AVG venta neta',
      },
      {
        id: 3,

        nombre: 'AVG N° Comandas',
      },
    ])
    const datos = ref([])
    const series = ref([
      {
        name: 'Ventas generales',
        data: [],
      },
    ])
    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'radar',
      },
      dataLabels: {
        enabled: true,
        formatter: val => {
          if (val > 1000000) {
            return (val / 1000000).toFixed(2) + 'M'
          }
          else if (val > 1000) {
            return (val / 1000).toFixed(2) + 'K'
          }
           else{
            return (val).toFixed()
          }
         
        },
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff'],
            },
          },
        },
      },
      colors: ['#FF4560'],
      markers: {
        size: 4,
        colors: ['#fff'],
        strokeColor: '#FF4560',
        strokeWidth: 2,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        tickAmount: 7,
        labels: {
          formatter: function (val, i) {
            if (i % 2 === 0) {
              return val
            } else {
              return ''
            }
          },
        },
      },
      noData: {
        text: 'No se encontro resultados',
        align: 'center',
        verticalAlign: 'middle',
      },
    })
    const tipoBusqueda = ref(0)
    const cargando = ref(true)
    const filtros = ref({
      fecha: {
        desde: moment(),
        hasta: moment(),
      },
      limit: 15,
    })
    onBeforeMount(() => {
      if (props.fechaIni !== undefined) {
        filtros.value.fecha.desde = props.fechaIni.desde
        filtros.value.fecha.hasta = props.fechaIni.hasta
      }
      cargarDatos()
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      filtros.value.grupo_negocio = fecha.grupo_negocio
      cargarDatos()
    }
    watch(tipoBusqueda, () => {
      let seriesTemp = []
      let series2Temp = []
      let series3Temp = []
      let series4Temp = []
      datos.value.forEach((element, i) => {
        seriesTemp.push(element.total_neto)
        series2Temp.push(element.comandas)
        series3Temp.push(element.promedio_comandas.toFixed())
        series4Temp.push(element.promedio_ventas.toFixed(2))
      })

      let serie = []

      if (tipoBusqueda.value == 0) {
        serie.push({
          name: 'Venta neta',
          data: seriesTemp,
        })
      } else if (tipoBusqueda.value == 1) {
        serie.push({
          name: 'N° Comandas',
          data: series2Temp,
          show: false,
        })
      } else if (tipoBusqueda.value == 2) {
        serie.push({
          name: 'AVG Ventas Netas',
          data: series4Temp,
          show: false,
        })
      } else if (tipoBusqueda.value == 3) {
        serie.push({
          name: 'AVG N° Comandas',
          data: series3Temp,
        })
      }
      grafricaRef.value.updateSeries(serie)
    })
    const cargarDatos = () => {
      cargando.value = true
      datos.value = []
      MaestroService.RptDashCierreDiaDiasSemana(filtros.value)
        .then(response => {
          let seriesTemp = []
          let series2Temp = []
          let series3Temp = []
          let series4Temp = []
          let xaxisTemp = []
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            console.log(response.data)
            if (response.data.datos.length > 0) {
              response.data.datos.forEach((element, i) => {
                seriesTemp.push(element.total_neto)
                series2Temp.push(element.comandas)
                series3Temp.push(element.promedio_comandas.toFixed())
                series4Temp.push(element.promedio_ventas.toFixed(2))
                xaxisTemp.push(element.dia)
              })
            }
            grafricaRef.value.updateOptions({
              xaxis: {
                categories: xaxisTemp,
              },
            })

            let serie = []

            if (tipoBusqueda.value == 0) {
              serie.push({
                name: 'Venta neta',
                data: seriesTemp,
              })
            } else if (tipoBusqueda.value == 1) {
              serie.push({
                name: 'N° Comandas',
                data: series2Temp,
                show: false,
              })
            } else if (tipoBusqueda.value == 2) {
              serie.push({
                name: 'AVG Ventas Netas',
                data: series4Temp,
                show: false,
              })
            } else if (tipoBusqueda.value == 3) {
              serie.push({
                name: 'AVG N° Comandas',
                data: series3Temp,
              })
            }

            grafricaRef.value.updateSeries(serie)

            console.log({
              xaxis: {
                categories: xaxisTemp,
              },
            })
            console.log(seriesTemp)
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const exportExcel = () => {
      let dato = {
        nombreDocumento:
          'Ventas por dias de la semana ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        nombreHoja: 'hoja1',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }],
      }
      dato.cabecera = ['Dia', 'Venta neta', 'N° Comandas', 'AVG Venta Neta', 'AVG N° comanda']
      datos.value.forEach((item, i) => {
        let row = [
          { v: item.dia, t: 's' },
          {
            v: item.total_neto,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          { v: item.comandas, t: 'n' },
          {
            v: item.promedio_ventas,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          {
            v: item.promedio_comandas,
            t: 'n',
            s: {
              numFmt: '0',
            },
          },
        ]
        dato.rows.push(row)
      })

      FuncionesGenerales.exportarDataExcel(dato)
    }

    return {
      series,
      chartOptions,
      cargarDatos,
      grafricaRef,
      filtros,
      getFechas,
      FuncionesGenerales,
      tipo_visual,
      datos,
      cargando,
      icons: {
        mdiCalendarMonth,
        mdiCalendarToday,
        mdiTableEye,
        mdiChartBarStacked,
        mdiFileExcel,
      },
      tipoBusqueda,
      tipos,
      exportExcel,
    }
  },
}
</script>