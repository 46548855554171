var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-subtitle',[_vm._v(" Balance general ")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn-toggle',{attrs:{"dense":"","mandatory":"","color":"primary"},model:{value:(_vm.tipo_intervalo),callback:function ($$v) {_vm.tipo_intervalo=$$v},expression:"tipo_intervalo"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarMonth)+" ")])],1)]}}])},[_c('span',[_vm._v("Mensual")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarToday)+" ")])],1)]}}])},[_c('span',[_vm._v("Diario")])])],1),_c('v-btn-toggle',{staticClass:"ml-2",attrs:{"dense":"","mandatory":"","multiple":""},model:{value:(_vm.tipo_visual),callback:function ($$v) {_vm.tipo_visual=$$v},expression:"tipo_visual"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChartBarStacked)+" ")])],1)]}}])},[_c('span',[_vm._v("Grafica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTableEye)+" ")])],1)]}}])},[_c('span',[_vm._v("Tabla")])])],1),(_vm.filtrosShow == true)?_c('dos-fechas',{attrs:{"fechas":[
          _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4),
          _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4) ]},on:{"getFechas":_vm.getFechas}}):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipo_visual.includes(0)),expression:"tipo_visual.includes(0)"}]},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.cargando),expression:"cargando"}],attrs:{"indeterminate":"","color":"primary"}}),_c('apexchart',{ref:"grafricaRef",attrs:{"type":"line","height":"350","options":_vm.chartOptions,"series":_vm.series}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.cargarGrafica()}},model:{value:(_vm.tipoBusqueda),callback:function ($$v) {_vm.tipoBusqueda=$$v},expression:"tipoBusqueda"}},[_c('v-radio',{attrs:{"label":"Venta Bruta","value":"2"}}),_c('v-radio',{attrs:{"label":"Venta Neta","value":"1"}}),_c('v-radio',{attrs:{"label":"Iva","value":"3"}}),_c('v-radio',{attrs:{"label":"Servicio","value":"4"}}),_c('v-radio',{attrs:{"label":"Administracion","value":"5"}}),_c('v-radio',{attrs:{"label":"Locatarios","value":"6"}})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('strong',[_vm._v("Total")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.total_general))])])],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipo_visual.includes(1)),expression:"tipo_visual.includes(1)"}]},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('export-excel',{staticStyle:{"width":"unset"},attrs:{"data":_vm.exportarDataExcel(),"name":'Balance general grafica.xls'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-0 mt-0 mr-2",attrs:{"small":"","icon":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileExcel))])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"350"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Fecha")]),_vm._l((_vm.series),function(item){return _c('th',{key:item.name},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.cargando == true),expression:"cargando == true"}]},[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}),_c('span',{staticClass:"pt-2"},[_vm._v(" Cargando datos")])],1)]),_vm._l((_vm.datos),function(fila,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(fila.fecha))]),_c('td',[_vm._v(_vm._s(fila.total_neto))]),_c('td',[_vm._v(_vm._s(fila.total_bruto))]),_c('td',[_vm._v(_vm._s(fila.total_iva))]),_c('td',[_vm._v(_vm._s(fila.total_servicio))]),_c('td',[_vm._v(_vm._s(fila.total_administracion))]),_c('td',[_vm._v(_vm._s(fila.total_puesto))])])})],2)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }