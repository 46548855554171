<template>
  <div>
    <p class="text-2xl mb-6">Gerencia</p>
    <div>
      <v-row>
        <v-col>
          <dos-fechas :fechas="[fecha.desde, fecha.hasta]" @getFechas="getFechas"></dos-fechas>
        </v-col>
        <v-col>
          <select-grupo-negocio
            :SelectDefault="-1"
            :todos="true"
            @getSelect="getGrupoNegocio"
            :dense="true"
            :outlined="true"
          ></select-grupo-negocio>
        </v-col>
        <v-col cols="auto" class="text-center">
          <v-btn @click="buscar()" class="primary" small>Buscar</v-btn>
          <v-btn v-if="graficaCierreDiaRef && graficaCierreDiaRef.datos && graficaCierreDiaRef.datos.length > 0" @click="ImprimirPDF()" class="error ml-1" small> <v-icon left>{{ icons.mdiFilePdf }}</v-icon> PDF</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <grafica-cierre-dia :fechaIni="fecha" :filtrosShow="false" ref="graficaCierreDiaRef"></grafica-cierre-dia>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card>
            <grafica-productos-mas-vendidos :fechaIni="fecha" :filtrosShow="false" ref="graficaProductoMasVendidoRef">
            </grafica-productos-mas-vendidos>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card>
            <grafica-ventas-mesoneros :fechaIni="fecha" :filtrosShow="false" ref="graficaVentasMesoneroRef">
            </grafica-ventas-mesoneros>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <grafica-cierre-puestos :fechaIni="fecha" :filtrosShow="false" ref="graficaCierrePuestoRef">
            </grafica-cierre-puestos>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8">
          <v-card>
            <grafica-puesto-mas-vende :fechaIni="fecha" :filtrosShow="false" ref="graficaPuestoMasVendeRef">
            </grafica-puesto-mas-vende>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card>
            <grafica-radar-ventas-dias-semana
              :fechaIni="fecha"
              :filtrosShow="false"
              ref="graficaRadarVentasDiasSemanaRef"
            ></grafica-radar-ventas-dias-semana>
          </v-card>
        </v-col>

        <v-col cols="12" lg="8">
          <v-card>
            <GraficaVentasDiasSemanaoOrdenado
              :fechaIni="fecha"
              :filtrosShow="false"
              ref="graficaVentasDiasSemanaOrdenadoRef"
            ></GraficaVentasDiasSemanaoOrdenado>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card>
            <GraficaPuestoTipoMasVende :fechaIni="fecha" :filtrosShow="false" ref="graficaPuestoTipoMasVendeRef">
            </GraficaPuestoTipoMasVende>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <GraficaMapaVentasPorMesas :fechaIni="fecha" :filtrosShow="false" ref="graficaMapaVentasPorMesasref">
            </GraficaMapaVentasPorMesas>
          </v-card>
        </v-col>
      </v-row>
      <Pdf ref="pdfRef"></Pdf>
    </div>
  </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline, mdiFilePdf } from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import GraficaCierrePuestos from './componentes/GraficaCierrePuestos.vue'
import GraficaCierreDia from './componentes/GraficaCierreDia.vue'
import GraficaProductosMasVendidos from './componentes/GraficaProductosMasVendidos.vue'
import GraficaVentasMesoneros from './componentes/GraficaVentasMesoneros.vue'
import DosFechas from '@/components/DosFechas.vue'
import moment from 'moment'
import FuncionesGenerales from '@/funciones/funciones'
import GraficaPuestoMasVende from './componentes/GraficaPuestoMasVende.vue'
import GraficaRadarVentasDiasSemana from './componentes/GraficaRadarVentasDiasSemana.vue'
import GraficaPuestoTipoMasVende from './componentes/GraficaPuestoTipoMasVende.vue'
import GraficaVentasDiasSemanaoOrdenado from './componentes/GraficaVentasDiasSemanaoOrdenado.vue'
import GraficaMapaVentasPorMesas from './componentes/GraficaMapaVentasPorMesas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import Pdf from '@/components/Pdf.vue'
import store from '@/store'
// demos

export default {
  components: {
    GraficaCierrePuestos,
    GraficaCierreDia,
    GraficaProductosMasVendidos,
    GraficaVentasMesoneros,
    DosFechas,
    GraficaPuestoMasVende,
    GraficaRadarVentasDiasSemana,
    GraficaVentasDiasSemanaoOrdenado,
    GraficaPuestoTipoMasVende,
    GraficaMapaVentasPorMesas,
    SelectGrupoNegocio,
    Pdf,
  },
  setup() {
    const fecha = ref({
      desde: moment().startOf('month').format('YYYY-MM-DD'),
      hasta: moment().endOf('month').format('YYYY-MM-DD'),
    })
    const pdfRef = ref(null)
    const grupoNegocio = ref(-1)
    const graficaCierreDiaRef = ref(null)
    const graficaCierrePuestoRef = ref(null)
    const graficaProductoMasVendidoRef = ref(null)
    const graficaVentasMesoneroRef = ref(null)
    const graficaPuestoMasVendeRef = ref(null)
    const graficaRadarVentasDiasSemanaRef = ref(null)
    const graficaVentasDiasSemanaOrdenadoRef = ref(null)
    const graficaPuestoTipoMasVendeRef = ref(null)
    const graficaMapaVentasPorMesasref = ref(null)

    const getGrupoNegocio = datox => {
      if (datox != undefined) {
        grupoNegocio.value = datox.id
      }
    }

    const getFechas = dato => {
      fecha.value.desde = dato.desde
      fecha.value.hasta = dato.hasta
    }

    const buscar = () => {
      const dato = {
        desde: fecha.value.desde,
        hasta: fecha.value.hasta,
        grupo_negocio: grupoNegocio.value,
      }

      graficaProductoMasVendidoRef.value.getFechas(dato)
      graficaCierreDiaRef.value.getFechas(dato)
      graficaCierrePuestoRef.value.getFechas(dato)
      graficaVentasMesoneroRef.value.getFechas(dato)
      graficaPuestoMasVendeRef.value.getFechas(dato)
      graficaRadarVentasDiasSemanaRef.value.getFechas(dato)
      graficaVentasDiasSemanaOrdenadoRef.value.getFechas(dato)
      graficaPuestoTipoMasVendeRef.value.getFechas(dato)
      graficaMapaVentasPorMesasref.value.getFechas(dato)
    }
    
    const ImprimirPDF = async () => {
      


      /* ventas totales por mes */
      const bodyPdf = []
      const headPdf = [
        { content: 'MES' , styles: { halign: 'left' }},
        { content: 'TOTAL BRUTO' },
        { content: 'TOTAL NETO' },
        { content: 'TOTAL SERVICIO' },
        { content: 'TOTAL IVA' },
      ]

      const totales = [{content:'TOTALES' , styles: { fillColor: [143, 143, 143], textColor: [255, 255, 255] , fontStyle:'bold'}}, 0, 0, 0, 0]
      graficaCierreDiaRef.value.datos.forEach(element => {
        let arr = [
          element.fecha,
          '$ '+FuncionesGenerales.formatoNumeric(element.total_neto),
          '$ '+FuncionesGenerales.formatoNumeric(element.total_bruto),
          '$ '+FuncionesGenerales.formatoNumeric(element.total_servicio),
          '$ '+FuncionesGenerales.formatoNumeric(element.total_iva),
        ]
        totales[1] = totales[1] + element.total_neto
        totales[2] = totales[2] + element.total_bruto
        totales[3] = totales[3] + element.total_servicio
        totales[4] = totales[4] + element.total_iva
        bodyPdf.push(arr)
      })
      totales[1] = {content: '$ '+ FuncionesGenerales.formatoNumeric(totales[1]) , styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'}}
      totales[2] = {content:'$ '+FuncionesGenerales.formatoNumeric(totales[2]), styles: { fillColor: [143, 143, 143], textColor: [255, 255, 255], fontStyle:'bold' }}
      totales[3] = {content:'$ '+FuncionesGenerales.formatoNumeric(totales[3]), styles: { fillColor: [143, 143, 143], textColor: [255, 255, 255] , fontStyle:'bold'}}
      totales[4] = {content:'$ '+FuncionesGenerales.formatoNumeric(totales[4]), styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'} }
      bodyPdf.push(totales)
      /* ventas totales por mes */



    /* ventas totales por mes por area */
      const bodyPdf2 = []
      const headPdf2 = [
        { content: 'Fecha' , styles: { halign: 'left' } }  
      ]

      graficaCierrePuestoRef.value.datos[0].fecha.split(',').forEach(elem=>{
        headPdf2.push({ content: elem } )
      })
      headPdf2.push({ content: "TOTALES" } )


      

      let sumTotalGen = 0
      graficaCierrePuestoRef.value.datos.forEach(element => {
        let arr = [element.nombre]
        let sumTotal = 0
        element.data_bruto.split(',').forEach(elem=>{
          sumTotal += Number(elem) 
          arr.push( '$ '+FuncionesGenerales.formatoNumeric(elem)  )
        })
        arr.push('$ '+FuncionesGenerales.formatoNumeric(sumTotal)  )
        sumTotalGen += sumTotal
        bodyPdf2.push(arr)
      })

      const totales2 = [ {content:'TOTALES', styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'}}]
      graficaCierrePuestoRef.value.datos[0].total_bruto.split(',').forEach(elem=>{
        totales2.push({ content:'$ '+ FuncionesGenerales.formatoNumeric(elem) , styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'}})
      })
      totales2.push({ content: '$ '+ FuncionesGenerales.formatoNumeric(sumTotalGen), styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'}})

      bodyPdf2.push(totales2)
       /* ventas totales por mes por area */
 

      /* ventas totales por tipo */
      const bodyPdf3 = []
      const headPdf3 = [
        { content: 'GRUPO' , styles: { halign: 'left' }}, 
        { content: 'TOTAL' },
      ]

      const totales3 = [{content:'TOTALES' , styles: { fillColor: [143, 143, 143], textColor: [255, 255, 255] , fontStyle:'bold'}}, 0]
      graficaPuestoTipoMasVendeRef.value.datos.forEach(element => {
        let arr = [
          element.nombre,
          '$ '+FuncionesGenerales.formatoNumeric(element.total), 
        ]
        totales3[1] = totales3[1] + element.total 
        bodyPdf3.push(arr)
      })
      totales3[1] = {content: '$ '+ FuncionesGenerales.formatoNumeric(totales3[1]) , styles: { fillColor: [143, 143, 143] , textColor: [255, 255, 255], fontStyle:'bold'}}
       bodyPdf3.push(totales3)
      /* ventas totales por tipo */



       

      const datosInit = {
        orientacion: 'l',
        nombreDocumento: `Reporte general de ventas ${fecha.value.desde} al ${fecha.value.hasta}`,
        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: `Reporte general de ventas ${fecha.value.desde} al ${fecha.value.hasta}`,
          TamanoLetras: 12,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: true,
          datos: [
            { texto: 'Exportado por: ' + store.state.user.nombre + ' ' + store.state.user.apellido },
            { texto: 'Fecha: ' + moment().format('YYYY-MM-DD h:mm:ss a') },
          ],
        },
        sub1: {
          visible: false,
          datos: [],
        },
        sub2: {
          visible: false,
          datos: [],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Ventas total por mes', TamanoLetras: 12 },
              tablas: {},
              head: [headPdf],
              body: bodyPdf,
              styles: { fontSize: 8 , halign: 'right'}, 
              columnStyles :{
                0: { halign: 'left'  } 
              }
            } ,
            
            {
              addPagina:true,
              cabecera1:{visible:true} ,
              titulo:{visible:true}
            },
            
            {
              titulo: { texto: 'Ventas total por mes por area', TamanoLetras: 12 },
              tablas: {},
              head: [headPdf2],
              body: bodyPdf2,
              styles: { fontSize: 8 , halign: 'right'},
              columnStyles :{
                0: { halign: 'left'  } 
              }
            } ,
            {
              addPagina:true,
              cabecera1:{visible:true} ,
              titulo:{visible:true}
            },
            
            {
              titulo: { texto: 'Ventas agrupada por tipo', TamanoLetras: 12 },
              tablas: {},
              head: [headPdf3],
              body: bodyPdf3,
              styles: { fontSize: 8 , halign: 'right'},
              columnStyles :{
                0: { halign: 'left'  } 
              }
            } ,
           
            
          ],
        },
      }

      pdfRef.value.generarPDF(datosInit)
    }

    onBeforeMount(() => {})

    return {
      icons:{
        mdiFilePdf
      },
      FuncionesGenerales,
      graficaCierreDiaRef,
      graficaCierrePuestoRef,
      graficaProductoMasVendidoRef,
      graficaVentasMesoneroRef,
      graficaPuestoMasVendeRef,
      graficaRadarVentasDiasSemanaRef,
      graficaVentasDiasSemanaOrdenadoRef,
      graficaPuestoTipoMasVendeRef,
      graficaMapaVentasPorMesasref,
      getFechas,
      fecha,
      buscar,
      getGrupoNegocio,
      grupoNegocio,
      pdfRef,
      ImprimirPDF,
    }
  },
}
</script>
  