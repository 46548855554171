var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-subtitle',[_vm._v("Ventas por dias de la semana")])],1),_c('v-col',[_c('v-row',{staticClass:"text-right"},[_c('v-col',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tipos.find(function (i) { return i.id == _vm.tipoBusqueda; }).nombre)+" ")])]}}])},[_c('v-list',_vm._l((_vm.tipos),function(item){return _c('v-list-item',{key:item.id,class:_vm.tipoBusqueda == item.id ? 'primary white--text' : '',attrs:{"link":""},on:{"click":function($event){_vm.tipoBusqueda = item.id}}},[_vm._v(" "+_vm._s(item.nombre)+" ")])}),1)],1)],1),_c('v-col',[_c('v-btn-toggle',{attrs:{"dense":"","mandatory":"","multiple":""},model:{value:(_vm.tipo_visual),callback:function ($$v) {_vm.tipo_visual=$$v},expression:"tipo_visual"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChartBarStacked)+" ")])],1)]}}])},[_c('span',[_vm._v("Grafica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTableEye)+" ")])],1)]}}])},[_c('span',[_vm._v("Tabla")])])],1)],1),(_vm.filtrosShow == true)?_c('v-col',[_c('dos-fechas',{attrs:{"fechas":[
              _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4),
              _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4) ]},on:{"getFechas":_vm.getFechas}})],1):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipo_visual.includes(0)),expression:"tipo_visual.includes(0)"}]},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.cargando),expression:"cargando"}],attrs:{"indeterminate":"","color":"primary"}}),_c('apexchart',{ref:"grafricaRef",attrs:{"type":"radar","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipo_visual.includes(1)),expression:"tipo_visual.includes(1)"}]},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-0 mt-0 mr-2",attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.exportExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileExcel))])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"350"}},[(_vm.datos.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Dia")]),_c('th',[_vm._v("Venta neta")]),_c('th',[_vm._v("N° comanda")]),_c('th',[_vm._v("AVG Venta neta")]),_c('th',[_vm._v("AVG N° comanda")])])]):_vm._e(),_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.cargando == true),expression:"cargando == true"}]},[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}),_c('span',{staticClass:"pt-2"},[_vm._v(" Cargando datos")])],1)]),_vm._l((_vm.datos),function(fila,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(fila.dia))]),_c('td',[_vm._v("$"+_vm._s(fila.total_neto.toFixed(2)))]),_c('td',[_vm._v(_vm._s(fila.comandas))]),_c('td',[_vm._v("$"+_vm._s(fila.promedio_ventas.toFixed(2)))]),_c('td',[_vm._v(_vm._s(fila.promedio_comandas.toFixed()))])])})],2)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }