<template>
  <div>
    <v-row>
      <v-col>
        <v-card-subtitle>Ventas por area</v-card-subtitle>
      </v-col>
      <v-col class="text-right">
        <v-select
          @change="cargarDatosGrafica()"
          v-model="idPuestoSelect"
          :items="datos"
          label="Locatario/Area"
          dense
          outlined
          item-text="nombre"
          item-value="id_puesto"
        ></v-select>
        <v-btn-toggle v-model="tipo_intervalo" dense mandatory color="primary">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiCalendarMonth }}
                </v-icon>
              </v-btn>
            </template>
            <span>Mensual</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiCalendarToday }}
                </v-icon>
              </v-btn>
            </template>
            <span>Diario</span>
          </v-tooltip>
        </v-btn-toggle>
        <v-btn-toggle class="ml-2" v-model="tipo_visual" dense mandatory multiple>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiChartBarStacked }}
                </v-icon>
              </v-btn>
            </template>
            <span>Grafica</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiTableEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Tabla</span>
          </v-tooltip>
        </v-btn-toggle>
        <dos-fechas
          v-if="filtrosShow == true"
          :fechas="[
            FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
            FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
          ]"
          @getFechas="getFechas"
        ></dos-fechas>
      </v-col>
    </v-row>

    <div v-show="tipo_visual.includes(0)">
      <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      <apexchart ref="grafricaRef" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
    <div v-show="tipo_visual.includes(1)">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small icon color="success" class="pt-0 mt-0 mr-2" @click="exportExcel()">
                <v-icon>{{ icons.mdiFileExcel }}</v-icon>
              </v-btn>
            </template>
            <span>Exportar Excel</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense fixed-header height="350">
            <thead v-if="datos.length > 0">
              <tr>
                <th>Fecha</th>
                <th v-for="(item, index) in datos[0].fecha.split(',')" :key="index">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="cargando == true">
                <td colspan="7" class="text-center">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  <span class="pt-2"> Cargando datos</span>
                </td>
              </tr>
              <tr v-for="(fila, index) in datos" :key="index">
                <td>{{ fila.nombre }}</td>
                <td v-for="(dato, i) in fila.data_bruto.split(',')" :key="i">
                  {{ dato }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import moment from 'moment'
import { mdiCalendarMonth, mdiCalendarToday, mdiTableEye, mdiChartBarStacked, mdiFileExcel } from '@mdi/js'

export default {
  props: {
    filtrosShow: Boolean,
    fechaIni: Object,
  },
  components: {
    DosFechas,
  },
  setup(props) {
    const grafricaRef = ref(null)
    const tipo_intervalo = ref(0)
    const tipo_visual = ref([0])
    const datos = ref([])
    const series = ref([
      {
        name: 'Venta Neta',
        data: [0],
      },
    ])
    const chartOptions = ref({
      title: {
        text: 'Ventas por areas',
        floating: false, 
        align: 'left',
        style: {
          color: '#444',
        },
      },
      chart: {
        height: 350,
        type: 'line',
        stacked: 'true',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => {
          if (val > 1000000) {
            return (val / 1000000).toFixed(2) + 'M'
          }
          else if (val > 1000) {
            return (val / 1000).toFixed(2) + 'K'
          }
           else{
            return (val).toFixed()
          }
         
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      legend: {
        show: true,
      },
      xaxis: {
        categories: [0],
      },
      legend: {
        offsetY: 40,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' $'
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      noData: {
        text: 'No se encontro resultados',
        align: 'center',
        verticalAlign: 'middle',
      },

   
    })
    const cargando = ref(true)
    const filtros = ref({
      fecha: {
        desde: moment(),
        hasta: moment(),
      },
      id_puesto: -1,
      id_cierre: -1,
    })

    watch(tipo_intervalo, () => {
      cargarDatos()
    })

    const cargarDatos = () => {
      if (tipo_intervalo.value == 0) {
        cargarDatosMes()
      } else {
        cargarDatosDia()
      }
    }

    onBeforeMount(() => {
      if (props.fechaIni !== undefined) {
        filtros.value.fecha.desde = props.fechaIni.desde
        filtros.value.fecha.hasta = props.fechaIni.hasta
      }
      cargarDatos()
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      filtros.value.grupo_negocio = fecha.grupo_negocio
      cargarDatos()
    }

    const idPuestoSelect = ref(null)

    const cargarDatosGrafica = () => {
      let seriesTemp = []
      datos.value.forEach((element, i) => {
         if (element.id_puesto == idPuestoSelect.value) {
          seriesTemp.push({
            name: FuncionesGenerales.camelCase(element.nombre),
            type: 'column',
            json: 'total_servicio',
            data: element.data_bruto.split(','),
          })

          grafricaRef.value.updateOptions({
            title: {
              text: `Ventas ${element.nombre} ${filtros.value.fecha.desde} | ${filtros.value.fecha.hasta}`,

              floating: false, 
              align: 'center',
              style: {
                color: '#444',
              },
            },
          })
        }
      })

      grafricaRef.value.updateSeries(seriesTemp)
    }

    const cargarDatosDia = () => {
      cargando.value = true
      datos.value = []
      MaestroService.RptDashCierrePuesto(filtros.value)
        .then(response => {
          let seriesTemp = []
          let xaxisTemp = {}
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data)
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
              response.data.datos.forEach((element, i) => {
                if (i == 0) {
                  xaxisTemp = {
                    xaxis: {
                      categories: element.fecha.split(','),
                    },
                  }
                }
              })
            }
            grafricaRef.value.updateOptions(xaxisTemp)
            cargarDatosGrafica()
            console.log(chartOptions.value)
            console.log(series.value)
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatosDia',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const cargarDatosMes = () => {
      cargando.value = true
      datos.value = []
      MaestroService.RptDashCierrePuestoMes(filtros.value)
        .then(response => {
          let seriesTemp = []
          let xaxisTemp = {}
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data)
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
              response.data.datos.forEach((element, i) => {
                if (i == 0) {
                  xaxisTemp = {
                    xaxis: {
                      categories: element.fecha.split(','),
                    },
                  }
                  /*seriesTemp = [{
                                        name: "Venta Bruta general",
                                        data: element.total_bruto.split(',')
                                    }]*/
                }
              })
            }
            grafricaRef.value.updateOptions(xaxisTemp)
            cargarDatosGrafica()

            console.log(chartOptions.value)
            console.log(series.value)
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatosMes',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const exportExcel = () => {
      let dato = {
        nombreDocumento:
          'Grafica Ventas por locatario ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        nombreHoja: 'hoja1',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 20 }],
      }
      datos.value.forEach((item, i) => {
        if (i == 0) {
          dato.cabecera = ['Puesto'].concat(item.fecha.split(','))
        }
        console.log([item.nombre].concat(item.data_bruto.replace(/,/g, '|').replace(/[.]/g, ',').split('|')))

        let row = []
        row.push({ v: item.nombre, t: 's' })
        item.data_bruto
          .replace(/,/g, '|')
          .split('|')
          .forEach(ro => {
            row.push({
              v: ro,
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            })
          })
        dato.rows.push(row)
      })

      FuncionesGenerales.exportarDataExcel(dato)
    }
    return {
      series,
      chartOptions,
      cargarDatos,
      grafricaRef,
      filtros,
      getFechas,
      FuncionesGenerales,
      tipo_visual,
      datos,
      cargando,
      tipo_intervalo,
      icons: {
        mdiCalendarMonth,
        mdiCalendarToday,
        mdiTableEye,
        mdiChartBarStacked,
        mdiFileExcel,
      },
      exportExcel,
      idPuestoSelect,
      cargarDatosGrafica,
    }
  },
}
</script>