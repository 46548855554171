<template>
  <div>
    <v-row>
      <v-col>
        <v-card-subtitle>Mapa Ventas Mesas</v-card-subtitle>
      </v-col>
      <v-col class="text-right">
        <v-btn-toggle class="ml-2" v-model="tipo_visual" dense mandatory multiple>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiChartBarStacked }}
                </v-icon>
              </v-btn>
            </template>
            <span>Grafica</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon color="green darken-2">
                  {{ icons.mdiTableEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Tabla</span>
          </v-tooltip>
        </v-btn-toggle>
        <dos-fechas
          v-if="filtrosShow == true"
          :fechas="[
            FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
            FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
          ]"
          @getFechas="getFechas"
        ></dos-fechas>
      </v-col>
    </v-row>
    <div v-show="tipo_visual.includes(0)">
      <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      <apexchart ref="grafricaRef" type="treemap" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
    <div v-show="tipo_visual.includes(1)">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small icon color="success" class="pt-0 mt-0 mr-2" @click="exportExcel()">
                <v-icon>{{ icons.mdiFileExcel }}</v-icon>
              </v-btn>
            </template>
            <span>Exportar Excel</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense fixed-header height="350">
            <thead v-if="datos.length > 0">
              <tr>
                <th>Grupo</th>
                <th>Mesa</th>
                <th>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="cargando == true">
                <td colspan="7" class="text-center">
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  <span class="pt-2"> Cargando datos</span>
                </td>
              </tr>
              <tr v-for="(fila, index) in datos" :key="index">
                <td>{{ fila.grupo }}</td>
                <td>{{ fila.mesa }}</td>
                <td>${{ fila.total }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import { mdiCalendarMonth, mdiCalendarToday, mdiTableEye, mdiChartBarStacked, mdiFileExcel } from '@mdi/js'
import moment from 'moment'
export default {
  props: {
    filtrosShow: Boolean,
    fechaIni: Object,
  },
  components: {
    DosFechas,
  },
  setup(props) {
    const grafricaRef = ref(null)
    const tipo_visual = ref([0])
    const datos = ref([])
    const series = ref([])
    const chartOptions = ref({
      legend: {
        show: true,
      },
      chart: {
        height: 350,
        type: 'treemap',
      },
      title: {
        text: 'Ventas por mesas',
        align: 'center',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
        /*formatter: function (text, op) {
          return [text, op.value]
        },*/

        formatter: (text, op) => {
          if (op.value > 1000000) {
            return [text, (op.value / 1000000).toFixed(2) + 'M']
          } else if (op.value > 1000) {
            return [text, (op.value / 1000).toFixed(2) + 'K']
           
          } else {
            return [text, op.value.toFixed()] 
          }
        },

        offsetY: -4,
      },
      noData: {
        text: 'No se encontro resultados',
        align: 'center',
        verticalAlign: 'middle',
      },
    })
    const cargando = ref(true)
    const filtros = ref({
      fecha: {
        desde: moment(),
        hasta: moment(),
      },
      limit: 15,
    })
    onBeforeMount(() => {
      if (props.fechaIni !== undefined) {
        filtros.value.fecha.desde = props.fechaIni.desde
        filtros.value.fecha.hasta = props.fechaIni.hasta
      }
      cargarDatos()
    })
    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      filtros.value.grupo_negocio = fecha.grupo_negocio
      cargarDatos()
    }
    const cargarDatos = () => {
      cargando.value = true
      datos.value = []
      MaestroService.RptDashVentaMesas(filtros.value)
        .then(response => {
          let seriesTemp = []
          let seriesTemp2 = []
          let grupo = ''
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            console.log(response.data)
            if (response.data.datos.length > 0) {
              response.data.datos.forEach((element, i) => {
                if (i == 0) {
                  grupo = element.grupo
                }
                if (element.grupo != grupo) {
                  seriesTemp.push({
                    name: grupo,
                    data: seriesTemp2,
                  })
                  grupo = element.grupo
                  seriesTemp2 = []
                }
                seriesTemp2.push({
                  x: element.mesa,
                  y: element.total,
                })
              })
            }
            seriesTemp.push({
              name: grupo,
              data: seriesTemp2,
            })

            grafricaRef.value.updateSeries(seriesTemp)

            console.log(seriesTemp)
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const exportExcel = () => {
      let dato = {
        nombreDocumento: 'Ventas por mesoneros ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        nombreHoja: 'hoja1',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 15 }],
      }

      dato.cabecera = ['Mesonero', 'Comandas', 'Total $']
      datos.value.forEach((item, i) => {
        let row = [
          { v: item.nombre, t: 's' },
          { v: item.count, t: 'n' },
          {
            v: item.total,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
        ]
        dato.rows.push(row)
      })

      FuncionesGenerales.exportarDataExcel(dato)
    }

    return {
      series,
      chartOptions,
      cargarDatos,
      grafricaRef,
      filtros,
      getFechas,
      FuncionesGenerales,
      tipo_visual,
      datos,
      cargando,
      icons: {
        mdiCalendarMonth,
        mdiCalendarToday,
        mdiTableEye,
        mdiChartBarStacked,
        mdiFileExcel,
      },
      exportExcel,
    }
  },
}
</script>